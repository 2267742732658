import axios from "axios";
import {ElMessage} from "element-plus";

export const token = "token";

axios.interceptors.request.use(config => {
    //  config.baseURL = "http://localhost:8088"
    console.log(config.baseURL + "/" + config.url);
    return config;
})
axios.interceptors.response.use(response => {
    if (response.status != 200) {
        ElMessage.error("network error, response code: " + response.status);
        throw new Error("network error, response code: " + response.status);
    }
    let data = response.data;
    let result = data.result;
    if (result.code != 1000) {
        ElMessage.error(result.message);
        throw new Error(result.message);
    }
    return response;
})


export function isLogin(): boolean {
    return window.sessionStorage.getItem(token) != undefined;
}

