
    import {Options, Vue} from 'vue-class-component';
    import {token} from "@/api/fn";
    import axios from "axios";

    interface DeviceDetail {
        orderId: string,
        deviceMac: string,
        preferredName: string
    }

    @Options({})
    export default class DeviceList extends Vue {
        deviceMac = "";
        deviceList: DeviceDetail[] = [];

        deviceDialogVisible = false;
        currentDeviceDetail: DeviceDetail = {
            orderId: "",
            deviceMac: "",
            preferredName: ""
        };

        pageNum = 1;
        pageSize = 10;
        total = 0;

        async created() {
            await this.loadDevices();
        }

        async loadDevices() {
            let orgId = window.sessionStorage.getItem("orgId");
            let response = await axios.get(`/realitycloudserver/org/${orgId}/devices`, {
                headers: {
                    "org-ui-token": window.sessionStorage.getItem(token)
                },
                params: {
                    pageSize: this.pageSize,
                    pageNum: this.pageNum
                }
            });
            let data = response.data.result.data;
            this.total = data.total;
            this.deviceList = [];
            data.orgPoDevices.forEach((device: any) => {
                this.deviceList.push({
                    deviceMac: device.deviceMac,
                    orderId: device.poId,
                    preferredName: device.preferredName
                })
            })
        }

        handleEdit(rowData: any) {
            console.log(JSON.stringify(rowData))
            this.currentDeviceDetail = rowData;
            this.deviceDialogVisible = true;
        }

        async updateDevice() {
            console.log(this.currentDeviceDetail);
            await axios.post(`/realitycloudserver/org/poDevice/${this.currentDeviceDetail.deviceMac}`, {
                deviceMac: this.currentDeviceDetail.deviceMac,
                preferredName: this.currentDeviceDetail.preferredName
            }, {
                headers: {
                    "org-ui-token": window.sessionStorage.getItem(token)
                },
            });
            this.deviceDialogVisible = false;
            await this.loadDevices();
        }

        async handleSizeChange() {
            console.log("page size" + this.pageSize)
            await this.loadDevices();
        }

        async handleCurrentChange() {
            console.log("page num" + this.pageNum)
            await this.loadDevices();
        }

        async search() {
            let response = await axios.get(`/realitycloudserver/org/poDevice/${this.deviceMac}`, {
                headers: {
                    "org-ui-token": window.sessionStorage.getItem(token)
                }
            });
            let device = response.data.result.data;
            if (device.deviceMac == undefined) {
                this.total = 0;
                this.deviceList = [];
            } else {
                this.total = 1;
                this.pageNum = 1;
                this.deviceList = [{
                    deviceMac: device.deviceMac,
                    orderId: device.poId,
                    preferredName: device.preferredName
                }];
            }
        }

    }
