<template>
    <div class="device-list">
        <el-card class="box-card">
            <template #header>
                <div class="card-header" style="text-align: left">
                    <el-input placeholder="device thing mac" prefix-icon="el-icon-search" v-model="deviceMac"
                              style="width: 200px ;margin :10px " clearable @clear="loadDevices()"></el-input>
                    <el-button class="button" type="primary" @click="search()">search</el-button>
                </div>
            </template>
            <el-table :data="deviceList" style="width: 98%" stripe border fit>
                <el-table-column prop="orderId" label="order id" min-width="180"></el-table-column>
                <el-table-column prop="deviceMac" label="thing mac" min-width="180"></el-table-column>
                <el-table-column prop="preferredName" label="preferred name" min-width="180"></el-table-column>
                <el-table-column label="operation" min-width="100px">
                    <template #default="scope">
                        <el-button size="mini" @click="handleEdit(scope.row)">edit</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination background @size-change="handleSizeChange()"
                           @current-change="handleCurrentChange()" :current-page="pageNum" v-model:currentPage="pageNum"
                           :page-sizes="[10, 20, 50, 100]" layout="total, sizes, prev, pager, next, jumper"
                           v-model:pageSize="pageSize" :page-size="pageSize"
                           :total="total">
            </el-pagination>
            <el-dialog title="modify device preferred name" v-model="deviceDialogVisible" width="30%">
                <el-form :model="currentDeviceDetail" label-width="30%">
                    <el-form-item label="order id">
                        <el-input v-model="currentDeviceDetail.orderId" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="device mac">
                        <el-input v-model="currentDeviceDetail.deviceMac" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="preferred name">
                        <el-input v-model="currentDeviceDetail.preferredName" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
          <span class="dialog-footer">
            <el-button @click="deviceDialogVisible = false">cancel</el-button>
            <el-button type="primary" @click="updateDevice()">confirm</el-button>
          </span>
                </template>
            </el-dialog>
        </el-card>
    </div>
</template>

<script lang="ts">
    import {Options, Vue} from 'vue-class-component';
    import {token} from "@/api/fn";
    import axios from "axios";

    interface DeviceDetail {
        orderId: string,
        deviceMac: string,
        preferredName: string
    }

    @Options({})
    export default class DeviceList extends Vue {
        deviceMac = "";
        deviceList: DeviceDetail[] = [];

        deviceDialogVisible = false;
        currentDeviceDetail: DeviceDetail = {
            orderId: "",
            deviceMac: "",
            preferredName: ""
        };

        pageNum = 1;
        pageSize = 10;
        total = 0;

        async created() {
            await this.loadDevices();
        }

        async loadDevices() {
            let orgId = window.sessionStorage.getItem("orgId");
            let response = await axios.get(`/realitycloudserver/org/${orgId}/devices`, {
                headers: {
                    "org-ui-token": window.sessionStorage.getItem(token)
                },
                params: {
                    pageSize: this.pageSize,
                    pageNum: this.pageNum
                }
            });
            let data = response.data.result.data;
            this.total = data.total;
            this.deviceList = [];
            data.orgPoDevices.forEach((device: any) => {
                this.deviceList.push({
                    deviceMac: device.deviceMac,
                    orderId: device.poId,
                    preferredName: device.preferredName
                })
            })
        }

        handleEdit(rowData: any) {
            console.log(JSON.stringify(rowData))
            this.currentDeviceDetail = rowData;
            this.deviceDialogVisible = true;
        }

        async updateDevice() {
            console.log(this.currentDeviceDetail);
            await axios.post(`/realitycloudserver/org/poDevice/${this.currentDeviceDetail.deviceMac}`, {
                deviceMac: this.currentDeviceDetail.deviceMac,
                preferredName: this.currentDeviceDetail.preferredName
            }, {
                headers: {
                    "org-ui-token": window.sessionStorage.getItem(token)
                },
            });
            this.deviceDialogVisible = false;
            await this.loadDevices();
        }

        async handleSizeChange() {
            console.log("page size" + this.pageSize)
            await this.loadDevices();
        }

        async handleCurrentChange() {
            console.log("page num" + this.pageNum)
            await this.loadDevices();
        }

        async search() {
            let response = await axios.get(`/realitycloudserver/org/poDevice/${this.deviceMac}`, {
                headers: {
                    "org-ui-token": window.sessionStorage.getItem(token)
                }
            });
            let device = response.data.result.data;
            if (device.deviceMac == undefined) {
                this.total = 0;
                this.deviceList = [];
            } else {
                this.total = 1;
                this.pageNum = 1;
                this.deviceList = [{
                    deviceMac: device.deviceMac,
                    orderId: device.poId,
                    preferredName: device.preferredName
                }];
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .el-pagination {
        margin-top: 20px;

    }
</style>
