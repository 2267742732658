<template>
  <h1>main</h1>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';

@Options({})
export default class Main extends Vue {

}
</script>
<style>

</style>
