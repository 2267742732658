<template>
    <div class="login">
        <el-form :model="loginForm" label-width="80px">
            <el-form-item label="Username" color="red">
                <el-input
                        v-model="loginForm.account"
                        type="text"
                        placeholder="please input username"
                ></el-input>
            </el-form-item>
            <el-form-item label="Password">
                <el-input
                        v-model="loginForm.password"
                        type="password"
                        placeholder="please input password"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="login">login</el-button>
                <el-button @click="reset">reset</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script lang="ts">
    import {Options, Vue} from "vue-class-component";
    import {model} from "@/api/model";
    import {token} from "@/api/fn";
    import axios from "axios";
    import LoginForm = model.LoginForm;


    @Options({
        components: {},
    })
    export default class Login extends Vue {
        loginForm: LoginForm = {
            password: "",
            account: "",
        };

        async login() {
            let responseData = await axios.post("/realitycloudserver/org/login", this.loginForm)
            window.sessionStorage.setItem(token, responseData.data.result.data.token);
            window.sessionStorage.setItem("orgId", responseData.data.result.data.orgId);
            await this.$router.push("/")
            return;
        }

        reset() {
            this.loginForm = {account: "", password: ""};
        }
    }
</script>
<style scoped>
    .login {
        background-color: cadetblue;
        position: absolute;
        border-radius: 10px;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
    }

    .login .el-form-item__label {
        color: #000000;
    }

    .el-form {
        margin: 30px;
    }
</style>
