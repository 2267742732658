import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";
import {home, index, login} from "@/api/path";
import OrderList from "@/components/OrderList.vue";
import DeviceList from "@/components/DeviceList.vue";
import Main from "@/views/Main.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: index,
        name: 'index',
        redirect: home
    },
    {
        path: home,
        name: 'home',
        component: Home,
        children: [
            {
                path: '',
                name: 'main',
                component: Main
            },
            {
                path: '/order/list',
                name: "order-list",
                component: OrderList
            },
            {
                path: "/device/list",
                name: "device-list",
                component: DeviceList
            }]
    },
    {
        path: login,
        name: 'login',
        component: Login
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
