
import {Options, Vue} from 'vue-class-component';
import {token} from "@/api/fn";

@Options({})
export default class Home extends Vue {

  account = "admin";

  pwdChangeDto = {
    oldPassword: "",
    password: "",
    confirmPwd: ""
  }

  changePwdDialogVisible = false;

  async getOrderList() {
    console.log("order list")
    await this.$router.push("/order/list")
  }

  async getDeviceList() {
    console.log("device list")
    await this.$router.push("/device/list")
  }

  async returnMain() {
    await this.$router.push("/")
  }

  async logout() {
    console.log("logout")
    window.sessionStorage.removeItem(token);
    await location.reload()
  }

  async changePassword() {
    this.changePwdDialogVisible = false;
    //todo change password

  }

}
