
    import {Options, Vue} from "vue-class-component";
    import {model} from "@/api/model";
    import {token} from "@/api/fn";
    import axios from "axios";
    import LoginForm = model.LoginForm;


    @Options({
        components: {},
    })
    export default class Login extends Vue {
        loginForm: LoginForm = {
            password: "",
            account: "",
        };

        async login() {
            let responseData = await axios.post("/realitycloudserver/org/login", this.loginForm)
            window.sessionStorage.setItem(token, responseData.data.result.data.token);
            window.sessionStorage.setItem("orgId", responseData.data.result.data.orgId);
            await this.$router.push("/")
            return;
        }

        reset() {
            this.loginForm = {account: "", password: ""};
        }
    }
