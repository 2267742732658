<template>
  <div class="home">
    <el-container>
      <el-aside width="200px">
        <h5 @click="returnMain" style="color: white;text-align: center">index</h5>
        <el-menu :uniqueOpened="true" default-active="2" class="menu-tab" background-color="#545c64" text-color="#fff"
                 active-text-color="#ffd04b">
          <el-submenu index="1">
            <template #title>
              <i class="el-icon-location"></i> <span>order manage</span>
            </template>
            <!--          <el-menu-item index="1-1" @click="getOrderList">订单列表</el-menu-item>-->
            <el-menu-item index="1-2" @click="getDeviceList">device list</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <el-dropdown>
                <span class="el-dropdown-link">
               <i class="iconfont icon-yonghu"></i> {{ account }}<i class=" el-icon-arrow-down el-icon--right"></i>
              </span>
           <!-- <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="changePwdDialogVisible=true">change password</el-dropdown-item>
              </el-dropdown-menu>
            </template>-->
          </el-dropdown>
          <el-button type="danger" circle icon="iconfont icon-tuichu" style="line-height: 90%;margin-left: 20px"
                     @click="logout()"></el-button>

          <el-dialog title="change password" v-model="changePwdDialogVisible" width="30%">
            <el-form :model="pwdChangeDto" label-width="30%">
              <el-form-item label="old password">
                <el-input v-model="pwdChangeDto.oldPassword" autocomplete="off" type="password"></el-input>
              </el-form-item>
              <el-form-item label="new password">
                <el-input v-model="pwdChangeDto.password" autocomplete="off" type="password"></el-input>
              </el-form-item>
              <el-form-item label="confirm new password">
                <el-input v-model="pwdChangeDto.confirmPwd" autocomplete="off" type="password"></el-input>
              </el-form-item>
            </el-form>
            <template #footer>
            <span class="dialog-footer">
              <el-button @click="changePwdDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="changePassword()">确 定</el-button>
            </span>
            </template>
          </el-dialog>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
        <el-footer>
          <span>Copyright © 2018 - 2021 &nbsp;&nbsp;&nbsp; 3Reality</span>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {token} from "@/api/fn";

@Options({})
export default class Home extends Vue {

  account = "admin";

  pwdChangeDto = {
    oldPassword: "",
    password: "",
    confirmPwd: ""
  }

  changePwdDialogVisible = false;

  async getOrderList() {
    console.log("order list")
    await this.$router.push("/order/list")
  }

  async getDeviceList() {
    console.log("device list")
    await this.$router.push("/device/list")
  }

  async returnMain() {
    await this.$router.push("/")
  }

  async logout() {
    console.log("logout")
    window.sessionStorage.removeItem(token);
    await location.reload()
  }

  async changePassword() {
    this.changePwdDialogVisible = false;
    //todo change password

  }

}
</script>
<style scoped>

.home {
  height: 100%;
  margin: 0;
  padding: 0;
}

.el-header {
  background-color: darkgray;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.el-container {
  background-color: #2c3e50;
  margin: 0;
  padding: 0;
  height: 100%;
}

.el-aside {
  background-color: #304156;
  height: 100%;
  padding: 0;
  margin: 0;
}

.el-menu {
  border-right: none 0 #e6e6e6;
}

.el-footer {
  background-color: #0173c0;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
}

.el-main {
  height: 100%;
}


</style>
