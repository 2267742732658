import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import {home, login} from "@/api/path";
import {isLogin} from "@/api/fn";
import "@/assets/mini-icon/iconfont.css";
import "@/assets/global.css"

export const app = createApp(App)
app.use(store);
app.use(router);
app.use(VueAxios, axios)
app.use(ElementPlus);
app.mount('#app');

export const axiosInstance = app.axios.create({
    baseURL: "http://localhost:8088"
})
router.beforeEach((to, from, next) => {
    console.log("check url:" + to.fullPath);
    let hasLogin = isLogin();

    if (to.path == login) {
        if (hasLogin) {
            return next(home);
        } else {
            return next()
        }
    } else {
        if (hasLogin) {
            return next();
        } else {
            return next(login);
        }
    }
})
